import { VisitorLogger } from '@wix/yoshi-flow-editor';
import {
  loyaltyUouCheckoutGetReward,
  loyaltyUouCheckoutLogin,
  loyaltyUouCheckoutClicks,
  loyaltyUouApplyRewardClick,
  loyaltyUouBuyCoupon,
} from '@wix/bi-logger-loyalty-checkout-uou/v2';

import { SelectedRewardType, FixedRewardSource } from './constants';
import { FixedReward } from './getValidFixedRewards';

interface ApplyFixedRewardParams {
  fixedReward: FixedReward;
  availableFixedRewards: FixedReward[];
  userPointsAmount: number;
}

export function createBiLogger(bi?: VisitorLogger | null) {
  const applyFlexibleReward = async (points: number) => {
    await bi?.report(loyaltyUouCheckoutGetReward({ points }));
  };

  const applyFixedReward = async ({ fixedReward, availableFixedRewards, userPointsAmount }: ApplyFixedRewardParams) => {
    const availableRewards = availableFixedRewards.map(({ id }) => id).join(',');
    const referralInfo = 'checkout';
    const rewardId = fixedReward.id;
    const rewardName = fixedReward.name;
    const totalPoints = userPointsAmount;
    const totalRewards = availableFixedRewards.length;

    if (fixedReward.source === FixedRewardSource.LoyaltyReward) {
      await bi?.report(
        loyaltyUouBuyCoupon({
          availableRewards,
          referralInfo,
          rewardId,
          rewardName,
          rewardPrice: fixedReward.costInPoints,
          rewardType: 'COUPON_REWARD',
          totalPoints,
          totalRewards,
        }),
      );
    } else {
      await bi?.report(
        loyaltyUouApplyRewardClick({
          availableRewards,
          referralInfo,
          rewardId,
          rewardName,
          rewardType: 'COUPON',
          totalPoints,
          totalRewards,
        }),
      );
    }
  };

  const logIn = async () => {
    await bi?.report(loyaltyUouCheckoutLogin({}));
  };

  const selectRewardType = async (rewardType: SelectedRewardType) => {
    await bi?.report(
      loyaltyUouCheckoutClicks({
        click: rewardType,
      }),
    );
  };

  const selectFixedReward = async (rewardName: string) => {
    await bi?.report(
      loyaltyUouCheckoutClicks({
        couponDropdown: rewardName,
      }),
    );
  };

  return {
    applyFlexibleReward,
    applyFixedReward,
    logIn,
    selectRewardType,
    selectFixedReward,
  };
}
